$primary-color: #005aeb;
$breakpoint: 36em;
$font-family: 'Centra No1', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;

.gdpr-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  box-shadow: 0 8px 16px 0 rgba(0, 11, 38, .24);
  color: #1f3366;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  z-index: 1000;

  .gdpr-container {
    display: flex;
    justify-content: center;
  }

  .cookie-icon {
    display: inline-flex;
    width: 3em;
    height: 3em;
    min-width: 3em;
    margin: 1.5em 0 1.5em 1.5em;
    fill: currentColor;
    vertical-align: middle;

    @media screen and (max-width: $breakpoint) {
      &.hide-small {
        display: none !important;
      }
    }
  }

  .gdpr-banner-content {
    display: flex;
    width: 100%;
    max-width: 72em;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 24px 0;

    @media screen and (max-width: $breakpoint) {
      margin-bottom: 12px;
    }
  }

  .gdpr-banner-icon-small {
    display: flex;
    width: 100%;
    justify-content: center;

    @media screen and (min-width: $breakpoint) {
      display: none !important;
    }

    svg {
      height: 48px;
      min-width: 48px;
      margin: 0 0 12px;
    }
  }

  .gdpr-banner-body {
    flex-basis: 100%;
    margin: 0 1.5em;

    @media screen and (max-width: $breakpoint) {
      margin: 0 12px;
    }

    .inner-body {
      max-width: 35em;
    }

    .gdpr-banner-headline {
      font-weight: 700;
      font-size: 22px;
      line-height: 26px;
      margin: 0;
      font-family: $font-family;
      letter-spacing: initial;
    }

    .gdpr-banner-text {
      line-height: 18px;
      font-size: 14px;
      margin: 8px 0 0;
      font-family: $font-family;
      letter-spacing: initial;
    }
  }

  .gdpr-banner-button-wrapper {
    flex-basis: 100%;
    margin: 1.5em 1.5em 0;

    @media screen and (max-width: $breakpoint) {
      margin: 24px 12px 0;
    }
  }

  .gdpr-banner-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height: 48px;
    width: 8.75em;
    border-radius: 8px;
    border-style: solid;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    padding: 4px 16px;
    -webkit-tap-highlight-color: transparent;
    text-shadow: none;
    min-height: 36px;
    margin-left: 0;
    margin-right: 0;
    border-width: 0;
    background-color: $primary-color;
    border-color: #fff;
    color: #fff;
    font-family: inherit;
    font-style: inherit;
    outline: none;
    touch-action: manipulation;
    cursor: pointer;

    @media screen and (max-width: $breakpoint) {
      width: 100%;
    }

    &:hover {
      background-color: #3a89f8;
    }

    &:active {
      background-color: #0f38bd;
    }
  }

  .gdpr-banner-link-wrapper {
    flex-basis: 100%;
    line-height: 18px;
    font-size: 14px;
    margin: 16px 24px 0;
  }

  .gdpr-banner-link {
    color: $primary-color;
    line-height: 18px;
    text-decoration: none;
    letter-spacing: initial;
    touch-action: manipulation;

    @media screen and (max-width: $breakpoint) {
      display: block;
      text-align: center;
    }

    &:visited {
      color: $primary-color;
      text-decoration: none;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}
